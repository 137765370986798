import React from 'react';
import { useRouter } from 'next/router';
import { logEvent } from 'firebase/analytics';
import styles from '../../styles/InvoiceCode.module.css';
import { useInvoiceState } from '../../context/state';
// import Link from 'next/link'
import { analytics } from '../../helper/firebaseConfig';

export default function PopUpConfirmData({ popUpConfirmData, triggerPopUpConfirmData }) {
  const [state] = useInvoiceState();
  const router = useRouter();

  const contactSeller = (url) => {
    if (typeof window !== 'undefined') {
      logEvent(analytics, `${process.env.FIREBASE_ENV}_PL_CTA_Hubungi_penjual`, {
        meta: 'detail invoice page',
      });
      router.push(url);
    }
  };

  const confirmData = (url) => {
    if (typeof window !== 'undefined') {
      logEvent(analytics, `${process.env.FIREBASE_ENV}_PL_CTA_Sudah_benar`);
      router.push(url);
    }
  };

  return (
    <div>
      <div aria-hidden onClick={triggerPopUpConfirmData} className="modal" style={popUpConfirmData ? { display: 'block' } : {}} />
      <div className="modal-content-confirm" style={{ overflow: 'scroll' }}>
        <div className="modal-header">
          <div className="modal-header-content">
            <p style={{ fontSize: '27px' }} className={styles.mainContentTotalTitle}>Perhatian</p>
          </div>
        </div>
        <div className="modal-body-info">
          <p style={{ fontSize: '14px', lineHeight: '23px', paddingBottom: '20px' }} className="modal-body-content">
            <span style={{ fontWeight: 'bold' }}>
              Harap pastikan nomor yang Anda gunakan benar dan terdaftar pada WhatsApp
            </span>
            , dikarenakan kami akan mengirimkan link konfirmasi ke nomor Anda melalui WhatsApp.
            Harap hubungi penjual jika ingin melakukan perubahan pada nomor.
            Aplikasi Keyta tidak bertanggung jawab jika terjadi kesalahan nomor.
          </p>
        </div>

        <footer className={styles.footerMultiple}>
          <button type="button" onClick={() => contactSeller(`https://wa.me/${state?.sellerPhone}`)} className={styles.footerButtonMultiple}>Hubungi Penjual</button>
          <button type="button" onClick={() => confirmData(`/${state.urlCode}/payment`)} className={styles.footerButtonMultiple}>Sudah Benar</button>
        </footer>
      </div>
    </div>
  );
}
