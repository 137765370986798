import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Link from 'next/link';
import { logEvent } from 'firebase/analytics';
import { useRouter } from 'next/router';
import styles from '../../styles/InvoiceCode.module.css';
import InstructionVARetail from './InstructionVARetail';
import InfoPayment from '../InfoPayment';
import { useInvoiceState } from '../../context/state';
import PopUpChangeMethod from '../popup/PopUpChangeMethod';
import { analytics } from '../../helper/firebaseConfig';
import * as api from '../../helper/api';
// import { db } from '../../helper/firebaseConfig'
// import { collection, query, where, onSnapshot } from "firebase/firestore";

export default function WaitingPage() {
  const [state, dispatch] = useInvoiceState();
  const [waitingTitle, setWaitingTitle] = useState('Menunggu Pembayaran');
  const [waitingDescription, setWaitingDescription] = useState('Segera selesaikan pembayaran Anda');
  const [transitionStage, setTransitionStage] = useState('fadeOut');

  const [popUpCancel, setPopUpCancel] = useState(false);
  const [disable, setDisable] = useState(false);
  // const [state?.finalStatus, setStatusPage] = useState(state?.finalStatus)

  const router = useRouter();
  let { status, message } = router.query;

  const triggerPopUpCancel = () => {
    setPopUpCancel(!popUpCancel);
  };

  const cancelPayment = async () => {
    const payload = {
      method: '',
      methodType: '',
      accountNumber: '',
      submit: false,
      nextPage: false,
    };

    async function triggerCancelPayment() {
      const data = await api.cancelPayment(state?.data?.transaction?.id);
      return data;
    }

    let err;
    try {
      setDisable(true);
      await triggerCancelPayment();
      dispatch({ type: 'SET_INVOICE', payload });
      window.location.reload();
    } catch (error) {
      err = true;
    }

    if (err) {
      const x = document.getElementById('snackbar');
      x.className = 'show';
      setTimeout(() => {
        x.className = x.className.replace('show', '');
        triggerPopUpCancel();
        window.location.reload();
        setDisable(false);
      }, 3000);
    }
  };

  useEffect(() => {
    setTransitionStage('fadeIn');
  }, []);

  const setFinalStatus = () => {
    const payload = { finalStatus: status,
                      failureCode: message };
    dispatch({ type: 'SET_INVOICE', payload });
  };

  useEffect(() => {
    switch (status) {
      case 'success':
        status = 'completed';
        break;
      case 'error':
        status = 'failed';
        break;
      default:
        break;
    }

    switch (message) {
      case 'ISSUER_SUSPECT_FRAUD':
        message = 'Card Declined';
        break;
      case 'DECLINED_BY_PROCESSOR':
        message = 'Card Declined';
        break;
      case 'EXPIRED_CARD':
        message = 'Expired Card';
        break;
      case 'INSUFFICIENT_BALANCE':
        message = 'Insufficient Balance';
        break;
      case 'STOLEN_CARD':
        message = 'Stolen Card';
        break;
      case 'INACTIVE_OR_UNAUTHORIZED_CARD':
        message = 'Inactive Card';
        break;
      case 'INVALID_CVV':
        message = 'Invalid CVN';
        break;
      case 'DECLINED_BY_ISSUER':
        message = 'Declined';
        break;
      default:
        break;
    }
    if (status) setFinalStatus();
  }, [status]);

  useEffect(() => {
    if (state?.finalStatus === 'pending') {
      setWaitingTitle('Menunggu Pembayaran');
      setWaitingDescription('Segera selesaikan pembayaran Anda');
    }

    if (state?.finalStatus === 'processed') {
      setWaitingTitle('Pembayaran Sedang Dicek');
      setWaitingDescription('Mohon untuk menunggu');
    }

    if (state?.finalStatus === 'completed') {
      setWaitingTitle('Pembayaran Berhasil');
      setWaitingDescription('Anda berhasil melakukan pembayaran via Keyta');
    }

    if (state?.finalStatus === 'expire') {
      setWaitingTitle('Waktu Telah Habis');
      setWaitingDescription('Anda bisa ganti metode pembayaran untuk menyelesaikan transaksi');
    }

    if (state?.finalStatus === 'failed') {
      setWaitingTitle('Pembayaran Gagal');
      setWaitingDescription('Pembayaran belum diterima. Silakan melakukan pembayaran ulang.');
    }
  // eslint-disable-next-line max-len
  }, [state?.finalStatus, waitingTitle, setWaitingTitle, waitingDescription, setWaitingDescription]);

  const finishPayment = () => {
    if (typeof window !== 'undefined') {
      logEvent(analytics, `${process.env.FIREBASE_ENV}_PL_CTA_Sudah_bayar`);
    }
    setFinalStatus('processed');
  };

  const contactSeller = (url) => {
    if (typeof window !== 'undefined') {
      logEvent(analytics, `${process.env.FIREBASE_ENV}_PL_CTA_Hubungi_penjual`, {
        meta: 'success payment page',
      });
    }
    router.push(url);
  };

  return (
    <>
      { popUpCancel && (
        <PopUpChangeMethod
          popUpCancel={popUpCancel}
          triggerPopUpCancel={triggerPopUpCancel}
          cancelPayment={cancelPayment}
          disable={disable}
        />
      )}
      <main className={`${styles.waitingPage} ${styles.content} ${styles[transitionStage]}`}>
        <img alt="imagestatus" className={styles.waitingPageImage} src={state?.finalStatus === 'processed' ? '/status/processed.gif' : `/status/${state?.finalStatus}.png`} />
        <div className={styles.waitingPageInfo}>
          <h4 className={styles.waitingPageInfoTitle}>{waitingTitle}</h4>
          { state?.finalStatus === 'failed'
            ? <h4>{state?.failureCode}</h4>
            : null }
          { state.method.code === 'OVO'
            ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                { state?.finalStatus !== 'processed' && state?.finalStatus !== 'completed' && state?.finalStatus !== 'expire' ? (
                  <>
                    <p
                      className={styles.waitingPageInfoDesc}
                    >
                      Silahkan buka aplikasi OVO untuk melunaskan pembayaran.
                    </p>
                    <img
                      alt="ovo"
                      style={{ marginTop: '15px' }}
                      width={280}
                      src="/ovoinstruction.png"
                    />
                  </>
                )
                  : <p className={styles.waitingPageInfoDesc}>{waitingDescription}</p>}
              </>
            )
            : <p className={styles.waitingPageInfoDesc}>{waitingDescription}</p>}
        </div>
        
        { state?.finalStatus === 'pending'
          ? <CountDown date={state.paymentExpired} title="Sisa waktu" expired={state.expired} />
          : state?.finalStatus === 'processed'
            ? <CountDown date={Date.now() + (10 * 60000)} title="Sistem sedang cek pembayaran Anda dalam" expired={state.expired} />
            : null}
        { state?.finalStatus === 'pending'
          ? state.method.type !== 'ewallet'
            ? <InstructionVARetail />
            : (
              <InfoPayment
                method={state.method}
                accountNumber={state.accountNumber}
                totalPayment={state.totalPayment}
              />
            )
          : (
            <InfoPayment
              method={state.method}
              accountNumber={state.accountNumber}
              totalPayment={state.totalPayment}
            />
          )}
      </main>
      <div id="snackbar">Ada kesalahan, coba lagi</div>
      { state?.finalStatus === 'pending' && state.method.type !== 'retail'
        ? (
          <footer className={styles.footer}>
            <button
              type="button"
              onClick={finishPayment}
              className={styles.footerButton}
            >
              Sudah Bayar

            </button>
          </footer>
        )
        : state?.finalStatus === 'processed'
          ? (
            <footer className={styles.footer}>
              <button
                type="button"
                onClick={() => { window.location.reload(); }}
                className={styles.footerButton}
              >
                Refresh

              </button>
            </footer>
          )
          : state?.finalStatus === 'completed'
            ? (
              <footer className={styles.footer}>
                {/* <Link href={`https://wa.me/62${state?.data?.transaction?.shop?.inv_phone || state?.data?.transaction?.shop?.phone}`}> */}
                <button
                  type="button"
                  onClick={() => contactSeller(`https://wa.me/${state?.sellerPhone}`)}
                  className={styles.footerButton}
                >
                  Hubungi Penjual
                </button>
                {/* </Link> */}
              </footer>
            )
            : state?.finalStatus === 'expire'
              ? (
                <footer className={styles.footer}>
                  <button
                    type="button"
                    onClick={triggerPopUpCancel}
                    className={styles.footerButton}
                  >
                    Ganti Metode Pembayaran
                  </button>
                </footer>
              )
              : state?.finalStatus === 'failed'
                ? (
                  <footer className={styles.footer}>
                    <button
                      type="button"
                      onClick={setPopUpCancel}
                      className={styles.footerButton}
                    >
                      Ganti Metode Pembayaran
                    </button>
                  </footer>
                )
                : null}
    </>
  );
}

function CountDown({ title, expired, date }) {
  const [timer, setTimer] = useState('');
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [state, dispatch] = useInvoiceState();

  const setFinalStatus = (status) => {
    const payload = { finalStatus: status };
    dispatch({ type: 'SET_INVOICE', payload });
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const target = new Date(date);

      const now = new Date();
      const difference = target.getTime() - now.getTime();

      if (difference > 0) {
        const d = Math.floor(difference / (1000 * 60 * 60 * 24));
        setDays(d);
        const h = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        setHours(h);
        const m = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        setMinutes(m);
        const s = Math.floor((difference % (1000 * 60)) / 1000);
        setSeconds(s);
        if (days >= 1) {
          setTimer(`${days} Hari ${hours} Jam ${minutes} Menit ${seconds} Detik`);
        } else {
          setTimer(`${hours} Jam ${minutes} Menit ${seconds} Detik`);
        }

        if (s % 30 === 0) {
          if (state.method.channel_type !== 'credit_card') {
            const dataStatus = await api.getPaymentData(state?.referenceID);
            let currentStatus = dataStatus?.data?.payment_status;
            if (currentStatus === 'ACTIVE') currentStatus = 'PENDING';

            if (currentStatus.toLowerCase() !== state.finalStatus.toLowerCase()) {
              window.location.reload();
            }
          }
        }
      } else if (typeof window !== 'undefined') {
        const refreshPage = sessionStorage.getItem('x-status');
        if (!refreshPage) {
          sessionStorage.setItem('x-status', true);
          window.location.reload();
        }
        setFinalStatus('expire');
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  return (
    <div className={styles.paymentSubmitPageCounter}>
      <p className={styles.paymentSubmitPageCounterTitle}>{title}</p>
      {
          expired
            ? <p className={styles.paymentSubmitPageCounterTimer}>0 Jam 0 Menit 0 Detik</p>
            : <p className={styles.paymentSubmitPageCounterTimer}>{timer}</p>
        }
    </div>
  );
}
