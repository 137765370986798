import React, { useState } from 'react';
import axios from 'axios';
import styles from '../../styles/InvoiceCode.module.css';
import { useInvoiceState } from '../../context/state';
import * as api from '../../helper/api';

export default function PopUpCancel({ popUpCancel, triggerPopUpCancel, setLoading }) {
  const [state, dispatch] = useInvoiceState();
  const [disable, setDisable] = useState(false);

  const onSelectHandler = () => {
    triggerPopUpCancel();
  };

  const cancelTransaction = async () => {
    setDisable(true);
    setLoading(true);
    const data = await api.cancelPayment(state?.data?.transaction?.id);

    const payload = {
      expired: true,
      cancelled: data?.transaction?.payment_status === 'cancelled',
    };

    dispatch({ type: 'SET_INVOICE', payload });
    triggerPopUpCancel();
    window.location.reload();
  };
  return (
    <div>
      <div
        aria-hidden
        type="button"
        onClick={triggerPopUpCancel}
        className="modal"
        style={popUpCancel ? { display: 'block' } : {}}
      />
      <div className="modal-content-cancel" style={{ overflow: 'scroll' }}>
        <div className="modal-header">
          <div className="modal-header-content">
            <p style={{ fontSize: '27px' }} className={styles.mainContentTotalTitle}>Batalkan Transaksi?</p>
          </div>
        </div>
        <div className="modal-body-info">
          <p style={{ fontSize: '14px', paddingBottom: '20px' }} className="modal-body-content">Transaksi ini akan dibatalkan dan tidak dapat dikembalikan, apakah Anda yakin?</p>
        </div>

        <footer className={styles.footerMultiple}>
          <button
            type="button"
            onClick={cancelTransaction}
            className={styles.footerButtonMultiple}
            disabled={disable}
          >
            Iya
          </button>
          <button
            type="button"
            onClick={onSelectHandler}
            className={styles.footerButtonMultiple}
          >
            Tidak
          </button>
        </footer>
      </div>
    </div>
  );
}
