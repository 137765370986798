import React from 'react';
import styles from '../styles/InvoiceCode.module.css';
import { currencyFormatter } from '../helper/formatting';

export default function InfoPayment({ accountNumber, method, totalPayment }) {
  return (
    <div className={styles.waitingPageDetail}>
      <p className={styles.waitingPageDetailTitle}>Nominal Pembayaran</p>
      <p className={styles.waitingPageDetailContent}>{currencyFormatter(totalPayment)}</p>
      <div className={styles.lineBreak} />

      <div className={styles.waitingPageDetailInfo}>
        <div>
          <p className={styles.waitingPageDetailTitle}>Metode Pembayaran</p>
          <div style={{
            display: 'flex', width: '50px', height: '36px', alignItems: 'center',
          }}
          >
            <img alt={method.code} style={{ maxHeight: '20px' }} src={`methods/${method.code.toUpperCase()}.png`} />
          </div>
        </div>
        {method.type !== 'retail' ? (
        <div>
          <p className={styles.waitingPageDetailTitle}>{method.type === 'ewallet' ? 'Nomor Digunakan' : method.type === 'VA' ? 'Nomor VA' : 'Nomor Kartu' }</p>
          <p className={styles.waitingPageDetailContent}>{accountNumber}</p>
        </div>
        ) : null}
      </div>
    </div>
  );
}
