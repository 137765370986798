import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import styles from '../../styles/InvoiceCode.module.css';
import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import DetailInvoice from '../../components/invoiceCode/DetailInvoice';
import WaitingPage from '../../components/invoiceCode/WaitingPage';
import Loading from '../../components/Loading';
import { useInvoiceState } from '../../context/state';
import PopUpLogin from '../../components/popup/PopUpLogin';
import * as api from '../../helper/api';

const splitInvoiceNumber = (invoiceCode) => {
  let editInvoiceCode = invoiceCode;
  if (invoiceCode.includes('-')) {
    const arrayCode = invoiceCode.split('-');
    editInvoiceCode = arrayCode[arrayCode.length - 1];
  }
  return editInvoiceCode;
};

const fetchTransaction = async (code) => {
  const data = await api.fetchTransactionData(code);
  return data;
};

const getSellerPhone = (invoiceDetail) => {
  const inv_phone = invoiceDetail?.transaction?.shop?.inv_phone;
  const phone = invoiceDetail?.transaction?.shop?.phone;
  const user_phone = invoiceDetail?.user_info?.phone_with_code;
  let sellerPhone;

  if (inv_phone != null && inv_phone !== '') {
    sellerPhone = `62${inv_phone}`;
  } else if (phone != null && phone !== '') {
    sellerPhone = `62${phone}`;
  } else {
    sellerPhone = user_phone;
  }
  return sellerPhone;
};

const getMethodDetail = (paymentType, selectedPayment, methods) => {
  let type;
  let expired;
  let guidance;
  if (paymentType === 'credit_card') {
    type = 'credit_card';
    let selectedMethod = methods.credit_card.filter((el) => el.code.toLowerCase() === selectedPayment.payment_method.toLowerCase())[0];
    if (!selectedMethod && methods.credit_card[0].code.toLowerCase() === 'credit_card') {
      [selectedMethod] = methods.credit_card;
    }
    guidance = selectedMethod.guidance;
    const paymentExpired = new Date(selectedPayment?.created_at);
    expired = new Date(paymentExpired.getTime() + selectedMethod.max_processing_time * 1000);
  }

  if (paymentType === 'ewallet') {
    type = 'ewallet';
    const selectedMethod = methods.ewallets.filter((el) => el.code.toLowerCase() === selectedPayment.payment_method.toLowerCase())[0];
    guidance = selectedMethod.guidance;
    expired = new Date(selectedPayment.expired_time);
  }

  if (paymentType === 'virtual_account') {
    type = 'VA';
    const selectedMethod = methods.virtual_account.filter((el) => el.code.toLowerCase() === selectedPayment.payment_method.toLowerCase())[0];
    guidance = selectedMethod.guidance;
    const paymentExpired = new Date(selectedPayment?.created_at);
    expired = new Date(paymentExpired.getTime() + selectedMethod.max_processing_time * 1000);
  }

  if (paymentType === 'retail') {
    type = 'retail';
    const selectedMethod = methods.retail.filter((el) => el.code.toLowerCase() === selectedPayment.payment_method.toLowerCase())[0];
    guidance = selectedMethod.guidance;
    const paymentExpired = new Date(selectedPayment?.created_at);
    expired = new Date(paymentExpired.getTime() + selectedMethod.max_processing_time * 1000);
  }

  return {
    type,
    guidance,
    expired,
  };
};

const getFinalStatus = (selectedPayment) => {
  let lowerCasefinalStatus = selectedPayment.status.toLowerCase();
  if (lowerCasefinalStatus === 'waiting_payment') lowerCasefinalStatus = 'pending';
  if (lowerCasefinalStatus === 'on_hold') lowerCasefinalStatus = 'completed';
  if (lowerCasefinalStatus === 'expired') lowerCasefinalStatus = 'expire';
  if (selectedPayment.is_cancelled) lowerCasefinalStatus = 'cancelled';
  return lowerCasefinalStatus;
};

export default function Home() {
  const [state, dispatch] = useInvoiceState();
  const router = useRouter();
  let { invoiceCode } = router.query;
  const [loading, setLoading] = useState(true);
  const [plExpireDate, setPLExpireDate] = useState(state.date);
  const [expired, setExpired] = useState(state.expired);
  const [invoiceData, setInvoiceData] = useState(state.data);
  const [err, setErr] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const triggerLoginModal = () => {
    setLoginModal(!loginModal);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) setLoginModal(false);
  }, [loginModal]);

  useEffect(async () => {
    try {
      if (invoiceCode) {
        invoiceCode = splitInvoiceNumber(invoiceCode);
        const { data, method, feeSetting } = await fetchTransaction(invoiceCode);
        const sellerPhone = getSellerPhone(data);
        const plDetail = data?.transaction?.payment_link;

        let code;
        let fee;
        let time_limit;
        let type;
        let isSubmit;
        let paymentMethodExpired;
        let feeByShop;
        let creditCardFeeByShop;
        let accountNumber;
        let totalPayment;
        let finalStatus;
        let guidance;
        let selectedPayment;
        let referenceID;

        if (plDetail) {
          const paymentStatus = data?.transaction?.payment_status;
          const paymentAttempts = data?.transaction?.payment_link?.order;

          if (paymentAttempts?.length > 0) {
            const firstDataPayment = paymentAttempts[0].payments;
            if (firstDataPayment.length > 0) {
              [selectedPayment] = firstDataPayment;
              referenceID = selectedPayment?.external_id;

              if (selectedPayment.payment_type === 'ewallet') {
                feeByShop = feeSetting?.ewallet_current?.fee_by_shop;
              } else if (selectedPayment.payment_type === 'virtual_account') {
                feeByShop = feeSetting?.virtual_account_current?.fee_by_shop;
              } else if (selectedPayment.payment_type === 'credit_card') {
                feeByShop = feeSetting?.credit_card_service_current?.fee_by_shop;
                creditCardFeeByShop = feeSetting?.credit_card_fee_current?.fee_by_shop;
              } else {
                feeByShop = false;
                creditCardFeeByShop = false;
              }

              finalStatus = getFinalStatus(selectedPayment);

              const paymentType = selectedPayment.payment_type;
              if (finalStatus !== 'cancelled') {
                accountNumber = plDetail?.order[0].account_number;
                totalPayment = parseInt(selectedPayment.amount, 10);
                if (!feeByShop) {
                  totalPayment += parseInt(selectedPayment.fee, 10);
                }
                if (!creditCardFeeByShop) {
                  totalPayment += parseInt(selectedPayment.credit_card_fee || 0, 10);
                }

                code = selectedPayment.payment_method;
                isSubmit = true;
                time_limit = 60;
                fee = 2;

                const selectedMethod = getMethodDetail(paymentType, selectedPayment, method);
                type = selectedMethod.type;
                paymentMethodExpired = selectedMethod.expired;
                guidance = selectedMethod.guidance;
              }
            }
          } else if (paymentStatus === 'paid') setExpired(true);
        }

        let customerNumber = data?.transaction?.customer?.whatsapp_number;
        if (customerNumber.charAt(0) === '6') customerNumber = customerNumber.slice(2);
        if (customerNumber.charAt(0) === '0') customerNumber = customerNumber.slice(1);

        const payload = {
          data,
          urlCode: invoiceCode,
          referenceID,
          invoiceCode,
          sellerPhone,
          finalStatus,
          totalPayment,
          methodData: method,
          submit: isSubmit || false,
          paymentExpired: paymentMethodExpired,
          date: plDetail?.expired_date,
          plExpired: plDetail?.expired_date,
          accountNumber: accountNumber || '',
          cancelled: data?.transaction?.payment_status === 'cancelled',
          customerNumber,
        };

        if (finalStatus !== 'cancelled') {
          if (data?.transaction?.payment_link?.order?.length > 0) {
            if (data?.transaction?.payment_link?.order[0].payments.length > 0) {
              payload.method = {
                code,
                fee,
                type,
                guidance,
                time_limit,
                unit: 'percent',
              };
            }
          }
        }

        setInvoiceData(data);
        setPLExpireDate(plDetail?.expired_date);
        dispatch({ type: 'SET_INVOICE', payload });
        setLoading(false);
      }
    } catch (error) {
      setErr(true);
      setLoading(false);
    }
  }, [invoiceCode]);

  useEffect(() => {
    // Paymentlink Expired
    if (plExpireDate !== 0) {
      const target = new Date(plExpireDate);
      const now = new Date();
      const difference = target.getTime() - now.getTime();
      if (difference <= 0) setExpired(true);
    }
  }, [expired, state]);

  if (loading) return <Loading />;
  if (expired || state.data?.transaction == null || state.cancelled) {
    return (
      <Layout nextPage={false}>
        <NotFound
          cancelled={state.cancelled}
          expired={state.expired}
          shop={invoiceData?.transaction}
        />
      </Layout>
    );
  }
  if (err) {
    return (
      <Layout nextPage={false}>
        <ErrorPage
          cancelled={state.cancelled}
          expired={state.expired}
          shop={invoiceData?.transaction}
        />
      </Layout>
    );
  }
  if (state.submit) {
    return (
      <Layout nextPage={false}>
        <WaitingPage />
      </Layout>
    );
  }
  return (
    <Layout loginModal={loginModal} nextPage={false}>
      { loginModal && (
        <PopUpLogin
          showModal={loginModal}
          triggerModal={triggerLoginModal}
        />
      )}
      <DetailInvoice triggerLogin={triggerLoginModal} setLoading={setLoading} />
    </Layout>
  );
}

function NotFound({ cancelled, shop }) {
  const [state] = useInvoiceState();

  return (
    <>
      <main className={styles.notFoundPage}>
        {
          cancelled
            ? <img alt="logoKeyta" width={133} height={70} style={{ marginTop: '200px', marginBottom: '20px' }} src="/cancelled.png" />
            : <img alt="logoKeyta" width={90} height={131} style={{ marginTop: '100px', marginBottom: '20px' }} src="/notfound.png" />

        }
        <div className={styles.notFoundPageInfo}>
          <h4 className={styles.notFoundPageInfoTitle}>{cancelled ? 'Transaksi Dibatalkan' : 'Mohon maaf,'}</h4>
          <p className={styles.notFoundPageInfoDesc}>{cancelled ? 'Transaksi anda telah berhasil dibatalkan' : 'Link tidak tersedia/sudah kadaluwarsa. Silahkan hubungi penjual untuk konfirmasi ulang pembayaran'}</p>
        </div>
      </main>
      <footer className={styles.footer}>
        <Link href={shop != null ? `https://wa.me/${state?.sellerPhone}` : '#'}>
          <button type="button" className={styles.footerButton}>{ shop != null ? 'Hubungi Penjual' : 'Tutup'}</button>
        </Link>
      </footer>
    </>
  );
}

function ErrorPage({ cancelled, shop }) {
  const [state] = useInvoiceState();

  return (
    <>
      <main className={styles.notFoundPage}>
        {
          cancelled
            ? <img alt="logoKeyta" width={133} height={70} style={{ marginTop: '200px', marginBottom: '20px' }} src="/cancelled.png" />
            : <img alt="logoKeyta" width={90} height={131} style={{ marginTop: '100px', marginBottom: '20px' }} src="/notfound.png" />

        }
        <div className={styles.notFoundPageInfo}>
          <h4 className={styles.notFoundPageInfoTitle}>{cancelled ? 'Transaksi Dibatalkan' : 'Mohon maaf,'}</h4>
          <p className={styles.notFoundPageInfoDesc}>{cancelled ? 'Transaksi anda telah berhasil dibatalkan' : 'Terjadi kesalahan'}</p>
        </div>
      </main>
      <footer className={styles.footer}>
        <Link href={shop != null ? `https://wa.me/${state?.sellerPhone}` : '#'}>
          <button type="button" className={styles.footerButton}>{ shop != null ? 'Hubungi Penjual' : 'Tutup'}</button>
        </Link>
      </footer>
    </>
  );
}
