/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import useEmblaCarousel from 'embla-carousel-react';
import { logEvent } from 'firebase/analytics';
import { currencyFormatter, truncate } from '../../helper/formatting';
import styles from '../../styles/InvoiceCode.module.css';
import PopUpCancel from '../popup/PopUpCancel';
import { useInvoiceState } from '../../context/state';
import { DotButton } from './CarouselButton';
import PopUpConfirmData from '../popup/PopUpConfirmData';
import PopUpMinimumPayment from '../popup/PopUpMinimumPayment';
import { analytics } from '../../helper/firebaseConfig';
import Footer from '../Footer';

export default function DetailInvoice({ setLoading, triggerLogin }) {
  const [state] = useInvoiceState();
  const [popUpCancel, setPopUpCancel] = useState(false);
  const [popUpConfirmData, setPopUpConfirmData] = useState(false);
  const [popUpMinimumPayment, setPopUpMinimumPayment] = useState(false);
  const router = useRouter();

  const triggerPopUpCancel = () => {
    setPopUpCancel(!popUpCancel);
  };

  const triggerPopUpMinimumPayment = () => {
    setPopUpMinimumPayment(!popUpMinimumPayment);
  };

  const triggerPopUpConfirmData = () => {
    const totalPayment = state?.data?.transaction?.total_payment;
    const minimumTotalPayment = 5000;
    if (totalPayment < minimumTotalPayment) {
      setPopUpMinimumPayment(!popUpMinimumPayment);
    } else {
      if (state?.data?.transaction?.is_payment_link) {
        router.push(`${state.urlCode}/payment`);
      } else {
        if (!popUpConfirmData) {
          if (typeof window !== 'undefined') {
            logEvent(analytics, `${process.env.FIREBASE_ENV}_PL_CTA_Lanjut`);
          }
        }
        setPopUpConfirmData(!popUpConfirmData);
      }
    }
  };

  return (
    <>
      {
        popUpMinimumPayment
          && (
          <PopUpMinimumPayment
            popUpMinimumPayment={popUpMinimumPayment}
            triggerPopUpMinimumPayment={triggerPopUpMinimumPayment}
          />
        )
      }
      { popUpCancel
          && (
          <PopUpCancel
            setLoading={setLoading}
            popUpCancel={popUpCancel}
            triggerPopUpCancel={triggerPopUpCancel}
          />
          )}
      { popUpConfirmData
        && (
        <PopUpConfirmData
          popUpConfirmData={popUpConfirmData}
          triggerPopUpConfirmData={triggerPopUpConfirmData}
        />
        )}

      <main className={styles.main}>
        {
          state?.data?.transaction?.is_payment_link
            ? (
              <>
                <CustomerDetail isPaymentLink={state?.data?.transaction?.is_payment_link} />
                <TotalPayment />
                <LoginModal triggerLogin={triggerLogin} />
                <Footer />
              </>
            )
            : (
              <>
                <WarningSection />
                <CustomerDetail isPaymentLink={state?.data?.transaction?.is_payment_link} />
                <ProductDetail />
                <TotalProduct />
                <br />
                <DeliveryDetail />
                <br />
                <TotalPayment />
                <LoginModal triggerLogin={triggerLogin} />
                <Footer />
              </>
            )
        }
        <footer className={styles.footer}>
          <button
            type="button"
            onClick={triggerPopUpConfirmData}
            className={styles.footerButton}
          >
            Lanjut

          </button>
        </footer>
      </main>
    </>
  );
}

function CustomerDetail({ isPaymentLink }) {
  const [state] = useInvoiceState();
  let waNumber = state?.data?.transaction?.customer.whatsapp_number;
  if (waNumber) {
    if (waNumber.startsWith('62')) {
      waNumber = `0${waNumber.slice(2)}`;
    }
  }
  return (
    <>
      <div className={styles.mainContent}>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { scale: 0.8, opacity: 0 },
            visible: { scale: 1, opacity: 1, transition: { delay: 0.4 } },
          }}
        >
          <p className={styles.mainContentTitle}>Detil Pelanggan</p>
        </motion.div>
      </div>

      <div className={styles.customer__detail}>
        <div className={styles.customer__detail__content}>
          <p style={{ fontWeight: 'bold' }}>Nama Pembeli</p>
          <div style={{ flexGrow: 1 }} />
          <p style={{ width: '45vw', textAlign: 'right' }}>{ state?.data?.transaction?.customer.customer_name }</p>
        </div>

        <div className={styles.customer__detail__content}>
          <p style={{ fontWeight: 'bold' }}>Nomor WhatsApp</p>
          <div style={{ flexGrow: 1 }} />
          <p style={{ width: '45vw', textAlign: 'right' }}>{ waNumber || state?.data?.transaction?.customer.channel_account }</p>
        </div>

        {
          isPaymentLink
            ? (
              <div className={styles.customer__detail__content}>
                <p style={{ fontWeight: 'bold' }}>Catatan</p>
                <div style={{ flexGrow: 1 }} />
                <p style={{ width: '45vw', textAlign: 'right' }}>{ state?.data?.transaction?.note }</p>
              </div>
            )
            : (
              <div className={styles.customer__detail__content}>
                <p style={{ fontWeight: 'bold' }}>Alamat Pembeli</p>
                <div style={{ flexGrow: 1 }} />
                <p style={{ width: '45vw', textAlign: 'right' }}>{ state?.data?.transaction?.customer.address }</p>
              </div>
            )
        }

      </div>
    </>
  );
}

function ProductDetail() {
  const [state] = useInvoiceState();

  return (
    <>
      <div className={styles.mainContent}>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { scale: 0.8, opacity: 0 },
            visible: { scale: 1, opacity: 1, transition: { delay: 0.4 } },
          }}
        >
          <p className={styles.mainContentTitle}>Detil Produk</p>
        </motion.div>
      </div>

      <div style={{ padding: '11px 0px' }}>
        {
          state.data?.transaction?.items.length > 0
            ? state.data?.transaction?.items.map((item) => (
              <div key={item.id} className={styles.mainContentProduct}>
                <div id="product-name">
                  <p style={{ paddingTop: 0 }} className={styles.mainContentProductNameTruncate}>
                    {item.quantity}
                    x
                    {
                  // eslint-disable-next-line no-unsafe-optional-chaining
                  ` ${truncate(item.name, Math.round((document?.getElementById('product-name')?.getBoundingClientRect()?.width * 1.91) / 14) - 3 || 25)}`
                }
                  </p>
                </div>
                {/* <div style={{flexGrow: 1}}></div> */}
                <div id="product-price">
                  <p style={{ paddingTop: '5px', paddingBottom: '5px' }} className={styles.mainContentProductName}>
                    <span style={{ color: '#F5B22D', marginRight: '10px' }}>
                      {
                    // eslint-disable-next-line no-nested-ternary
                    item.discount > 0
                      ? item.discount > 100
                        ? `${currencyFormatter((item.price - item.discount) * item.quantity)}`
                        : `${currencyFormatter((item.price - ((item.discount * item.price) / 100)) * item.quantity)}`
                      : null
                  }
                    </span>
                    {
                  item.discount > 0
                    ? <strike>{currencyFormatter((item.price) * item.quantity)}</strike>
                    : `${currencyFormatter((item.price) * item.quantity)}`
                }
                  </p>
                </div>
              </div>
            ))
            : (
              <div className={styles.mainContentProduct}>
                <div id="product-name">
                  <p className={styles.mainContentProductNameTruncate}>Harga Produk</p>
                </div>
                <div id="product-price">
                  <p
                    className={styles.mainContentProductName}
                  >
                    {currencyFormatter(state.data?.transaction?.total_price)}
                  </p>
                </div>
              </div>
            )
        }
      </div>
    </>
  );
}

function DeliveryDetail() {
  const [state] = useInvoiceState();

  return (
    <>
      <div className={styles.mainContent}>
        <p className={styles.mainContentTitle}>Pengiriman</p>
      </div>
      <div className={styles.mainContentProduct}>
        {
          state.data?.transaction?.expedition.code
            ? state.data?.transaction?.expedition.code.toLowerCase() === 'kurir pribadi'
              ? <p className={styles.mainContentProductName}>Kurir Pribadi</p>
              : <img alt="kurir pribadi" style={{ paddingTop: '13px', paddingLeft: '16px' }} width={100} src={`/courier/${state.data?.transaction?.expedition.code}.jpg`} />
            : <p className={styles.mainContentProductName}>Ongkos Kirim</p>
        }
        <p className={styles.mainContentProductName}>
          {
          state.data?.transaction?.expedition.expedition_service_type.toLowerCase() === 'belum ada'
            ? '' : `- ${state.data?.transaction?.expedition.expedition_service_type}`
        }
        </p>
        <div style={{ flexGrow: 1 }} />
        <p
          className={styles.mainContentProductName}
        >
          {currencyFormatter(state.data?.transaction?.shipping_price)}
        </p>
      </div>
      <div className={styles.lineBreak} style={{ paddingTop: '13px' }} />
    </>

  );
}

function TotalProduct() {
  const [state] = useInvoiceState();

  return (
    <>
      <div className={styles.lineBreak} style={{ paddingTop: '0px' }} />
      <div className={styles.mainContentProduct}>
        <p className={styles.mainContentProductName}>Total Harga Produk</p>
        <div style={{ flexGrow: 1 }} />
        <p
          className={styles.mainContentProductName}
        >
          {currencyFormatter(state.data?.transaction?.total_price)}
        </p>
      </div>
    </>
  );
}

function TotalPayment() {
  const [state] = useInvoiceState();

  return (
    <>
      <div className={styles.lineBreak} style={{ paddingTop: '20px' }} />
      <div className={styles.mainContentTotal}>
        <p className={styles.mainContentTotalTitle}>Total Pembayaran</p>
        <div style={{ flexGrow: 1 }} />
        <p
          className={styles.mainContentTotalPrice}
        >
          {currencyFormatter(state.data?.transaction?.total_payment)}
        </p>
      </div>
      <div className={styles.lineBreak} />
    </>
  );
}

function LoginModal({ triggerLogin }) {
  const [login, setLogin] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) setLogin(true);
  }, [triggerLogin]);

  if (login) return null;
  return (
    <div style={{ padding: '16px' }}>
      <div style={{
        padding: '12px',
        height: '100%',
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
      }}
      >
        <p style={{
          fontSize: '16px',
          lineHeight: '20px',
          color: '#42454D',
          fontWeight: '700',
        }}
        >
          Pembayaran Jadi Makin Mudah Dengan Login
        </p>
        <p style={{ fontWeight: '14px', color: '#42454D', marginTop: '5px' }}>
          Mudahkan proses pembayaranmu hanya dengan
          {' '}
          <span style={{ fontWeight: 'bold' }}>login menggunakan nomor telepon Anda</span>
        </p>
        <button
          onClick={() => triggerLogin()}
          type="button"
          className={styles.buttonLogins}
        >
          Daftar/Login
        </button>
      </div>
    </div>
  );
}

function WarningSection() {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [
    emblaApi,
  ]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi, setSelectedIndex]);

  useEffect(() => {
    if (emblaApi) {
      onSelect();
      setScrollSnaps(emblaApi.scrollSnapList());
      emblaApi.on('select', onSelect);
      // Embla API is ready
    }
  }, [emblaApi, setSelectedIndex, onSelect]);

  return (
    <div className={styles.warning}>
      <div className={styles.warning__content}>
        <div className="embla" ref={emblaRef}>
          <div className="embla__container">
            <div className="embla__slide">
              <img alt="info" style={{ paddingTop: '4px' }} src="/exclamation.svg" />
              <p className="embla__slide__text">Link untuk pelacakan dan penyelesaian pemesanan akan dikirim ke nomor WhatsApp Anda.</p>
            </div>
            <div className="embla__slide">
              <img alt="info" style={{ paddingTop: '4px' }} src="/exclamation.svg" />
              <p className="embla__slide__text">Pastikan detil nama pembeli, nomor WhatsApp dan alamat penerima sudah benar.</p>
            </div>
            <div className="embla__slide">
              <img alt="info" style={{ paddingTop: '4px' }} src="/exclamation.svg" />
              <p className="embla__slide__text">Silakan hubungi penjual jika ada detil pelanggan yang salah</p>
            </div>
          </div>
        </div>
        <div style={{ flexGrow: 1 }} />
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={Math.random()}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
