import React from 'react';
import styles from '../../styles/InvoiceCode.module.css';

export default function PopUpMinimumPayment({ popUpMinimumPayment, triggerPopUpMinimumPayment }) {
  return (
    <div>
      <div aria-hidden onClick={triggerPopUpMinimumPayment} className="modal" style={popUpMinimumPayment ? { display: 'block' } : {}} />
      <div className="modal-content-confirm" style={{ overflow: 'scroll' }}>
        <div className="modal-header">
          <div className="modal-header-content">
            <p style={{ fontSize: '20px' }} className={styles.mainContentTotalTitle}>Total Pembayaran Dibawah Jumlah Minimum</p>
          </div>
        </div>
        <div className="modal-body-info">
          <p style={{ fontSize: '14px', lineHeight: '23px', paddingBottom: '20px' }} className="modal-body-content">
            Minimum Total Pembayaran pada Invoice untuk penggunaan Payment Link adalah 
            <span style={{ fontWeight: 'bold' }}>Rp 5.000</span>
            . Silahkan gunakan metode pembayaran lain, atau hubungi penjual untuk menyesuaikan Total Pembayaran Invoice Anda.
          </p>
        </div>
        <footer className={styles.footerMultiple}>
          <button type="button" onClick={() => triggerPopUpMinimumPayment()} className={styles.footerButtonMultiple}>Tutup</button>
        </footer>
      </div>
    </div>
  );
}
