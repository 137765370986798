/* eslint-disable react/no-danger */
import React, { useState } from 'react';

function Accordion({ title, content }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div aria-hidden className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div style={{ flexGrow: 1 }} />
        <div>
          <img alt="down" src="down.png" />
        </div>
      </div>
      {isActive && <div className="accordion-content" dangerouslySetInnerHTML={{__html: content}} />}
    </div>
  );
}

export default Accordion;
