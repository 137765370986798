import React from 'react';
import Accordion from '../Accordion';
import styles from '../../styles/InvoiceCode.module.css';
import { useInvoiceState } from '../../context/state';
import { currencyFormatter } from '../../helper/formatting';

export default function Instruction() {
  const [state] = useInvoiceState();

  const copyButtonHandler = (text) => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(text);
      const x = document.getElementById('snackbar');
      x.className = 'show';

      setTimeout(() => { x.className = x.className.replace('show', ''); }, 3000);
    }
  };

  const changeCode = (code) => {
    if (state.method.type === 'retail') {
      if (code === 'ALGO') {
        return 'Alfamart & Alfamidi';
      }
      return code.replace('_', ' ');
    }
    return code.replace('_', ' ');
  };

  return (
    <>
      <main style={{ width: '100%' }} className={styles.mainPaymentSubmitPage}>
        <div className={styles.mainPaymentSubmitMethod} style={{ borderTop: 'none' }}>
          { state.method
            ? <img alt={state.method.code} style={{ maxHeight: '24px', marginRight: '16px' }} src={`methods/${state.method.code.toUpperCase()}.png`} />
            : null }
          {state.method.type !== 'retail' ? (
            <p className={styles.mainPaymentSubmitMethodText}>
              {changeCode(state.method.code)}
              {' '}
              (Dicek Otomatis)
            </p>
          ) : (
            <p className={styles.mainPaymentSubmitMethodRetailText}>
            Bayar di gerai
            </p>
          )}
        </div>
        <div className={styles.mainPaymentSubmitAccountBro}>
          <div className={styles.mainPaymentSubmitAccount}>
            <div className={styles.mainPaymentSubmitAccountDetail}>
              <p style={{ marginBottom: '5px' }} className={styles.mainText}>
                { state.method.type === 'ewallet'
                  ? 'Nomor Digunakan'
                  : state.method.type === 'VA'
                    ? 'No. Virtual Account'
                    : state.method.type === 'retail'
                      ? 'Kode Pembayaran'
                        : 'Nomor Kartu'}
              </p>
              <p className={styles.mainTitle}>{state.accountNumber}</p>
            </div>

            <div style={{ flexGrow: 1 }} />

            <div>
              <button
                type="button"
                className={styles.btnCopy}
                onClick={() => copyButtonHandler(state.accountNumber)}
              >
                Salin
              </button>
              <div id="snackbar">Nomor Berhasil Disalin</div>
            </div>
          </div>

          <div style={{ marginTop: '20px' }} className={styles.mainPaymentSubmitAccount}>
            <div className={styles.mainPaymentSubmitAccountDetail}>
              <p style={{ marginBottom: '5px' }} className={styles.mainText}>Nominal</p>
              <p className={styles.mainTitle}>{currencyFormatter(state.totalPayment)}</p>
            </div>

            <div style={{ flexGrow: 1 }} />
            <div>
              <button
                type="button"
                className={styles.btnCopy}
                onClick={() => copyButtonHandler(state.totalPayment)}
              >
                Salin
              </button>
              <div id="snackbar">Nominal Berhasil Disalin</div>
            </div>
          </div>
        </div>
      </main>

      <div style={{ width: '100%' }} className={styles.mainPaymentSubmitPage}>
        <div style={{ marginTop: '0px' }} className="accordion">
          {
                        state.method?.guidance?.map(({ title, content }) => (
                          <Accordion title={title} content={content} key={title} />
                        ))
                    }
        </div>
      </div>
    </>
  );
}
